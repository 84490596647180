#player {
  .card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    transition: 0.3s;
    border-radius: 5px;
    width: 50%;
  }

  .card p {
    margin-bottom: 15px;
  }

  video {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .container {
    padding: 2px 16px;
    line-height: 2rem;
  }

  .social-share {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
    line-height: 2rem;
  }

  @media screen and (max-width: 576px) {
    .social-share {
      width: 100%;
    }
  }

  @media screen and (max-width: 700px) {
    .card {
      width: 100%;
    }
  }
}
