@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

$color-primary: rgba(89, 49, 150, 0.94);
$color-primary-non-transparent: #593196;
$color-secondary: rgba(169, 145, 212, 0.99);
$color-success: #13b955;
$color-info: #009cdc;
$color-warning: #efa31d;
$color-danger: #fc3939;
$color-light: #f9f8fc;
$color-dark: #17141f;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: url("../images/background.jpg") fixed center/cover no-repeat !important;
  font-family: 'Raleway', sans-serif!important;
  color: $color-light;
  overflow-x: hidden!important;
}

#App {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#page-wrap {
  border: 2px solid $color-warning;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

.card-wrapper {
  padding: 20px;
  margin: 250px auto 200px;
  background-color: $color-primary;
  color: $color-light;
  border: 1px solid $color-secondary;
  border-radius: 10px 30px 10px 30px;
  box-shadow: 2px 2px 2px $color-primary;
  width: 90%;
}

.mt-5 {
  margin-top: 20px;
}

.mb-5 {
  margin-bottom: 20px;
}

.my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

a {
  text-decoration: none;
}

hr.dashed {
  border-top: 3px dashed $color-light;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.btn {
  border: none;
  color: white;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-sm {
  border: none;
  color: white;
  padding: 4px 8px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-warning {
  background-color: $color-warning;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 30px;
  left: 36px;
  top: 20px;
  z-index: 999999!important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $color-warning;

}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.form-control {
  border: 1px solid $color-dark;
  background-clip: padding-box;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
}

@media screen and (max-width: 768px) {
  .card-wrapper {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 160px;
    margin-top: 150px;
  }
}