#footer {
  position: absolute;
  width: 100%;
  color: $color-light;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 2px $color-dark;
  padding: 20px;
  background-color: $color-primary-non-transparent;
  line-height: 2em;
  left: 0;
  bottom: 0;
}