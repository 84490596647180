#last-four {
  .last-four-container {
    padding: 20px;
    margin: 250px auto 20px;
    background-color: $color-primary;
    color: $color-light;
    border: 1px solid $color-secondary;
    border-radius: 10px 30px 10px 30px;
    box-shadow: 2px 2px 2px $color-primary;
    width: 90%;
  }

  .last-four-container>* {
    flex: 2 2 ;
  }

  .card-last-four-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;

  }

  .card-last-four-container img {
    max-width: 100%;
    height: auto;
    box-shadow: 2px 2px 2px $color-dark;
  }

  @keyframes zoom {
    0% {
      transform: scale(0) rotate(90deg);
    }
    50% {
      transform: scale(1.1) rotate(0deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }

  .card-last-four {
    position: relative;
    text-align: center;
    color: $color-light;
    animation: zoom 0.5s ease-in-out;
  }

  .card-last-four a {
    color: $color-light;
    text-decoration: none;
  }

  .card-text {
    position: absolute;
    padding: 10px;
    background-color: rgba(255, 0, 0, 0.58);
    top: 95%;
    left: 50%;
    transform: translate(-50%, -95%);
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #333;
  }

  .card-text:hover {
    background-color: rgba(255, 0, 0, 0.87);
  }

  @media screen and (max-width: 768px) {
    .last-four-container {
      width: 100%;
      border-radius: 5px;
      margin-top: 150px;
    }
  }
}