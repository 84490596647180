@import url('https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap');

#header {
  background-image: repeating-linear-gradient($color-primary-non-transparent, #232323);
  background-repeat: repeat;
  background-size: 4px 4px;
  padding: 5px 10px;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 90px;
  font-weight: bold;
  font-family: 'Mouse Memoirs', sans-serif;
  text-shadow: 5px 3px #38254c;
  z-index: 9999;
}

.slogan {
  font-size: 30px;
  color: $color-warning;
}

@media screen and (max-width: 768px) {
  #header {
    display: none;
  }
}