@import url('https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap');

#sidebar {

  font-weight: bold;
  z-index: 9999999!important;

  .title {
    font-family: 'Mouse Memoirs', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    text-shadow: 5px 3px #38254c;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: $color-light;
    transition: color 0.2s;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: $color-warning;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: $color-secondary;
  }

  /* General sidebar styles */
  .bm-menu {
    background: $color-primary;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

}